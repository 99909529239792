body {
  background-color: #c6c6c6; /* Change this value to your desired background color for the page */
  margin: 0;
  font-family: Arial, sans-serif;
}

.container {
  text-align: center;
}

.custom-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #062454; /* Original blue color #4848f5 */
  padding: 10px;
  height: 100px;
  border-radius: 0; /* Remove border radius to make it span full width */
  width: 100vw; /* Make sure it takes the full width of the viewport */
  margin: 0; /* Remove any margin */
}

.navbar-title {
  color: white;
  font-size: 30px;
}

.center-horizontally {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dropdown-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.textbox-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto; 
}

.sortby-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto; 
  margin-right: auto; 
}

.filterbutton-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.input-info {
  text-align: center;
}

select {
  margin: 0 10px;
  padding: 5px;
  font-size: 16px;
}

button {
  padding: 5px 10px;
  font-size: 16px;
  background-color: #4848f5; /* Original blue color #4848f5 */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #3838d8; /* Original hover color */
}

/* Align the button to the right of the table */
.download-button {
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  background-color: #4848f5; 
  color: white;
}

.download-button:hover {
  background-color: #3838d8; /* Darken the original blue color for hover effect */
}

.sorting-button {
  float:center;
  background-color: #d8d8d8; 
  color: black;
}

.sorting-button:hover {
  background-color: #bebebe; 
}

.filter-button {
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
  background-color: #4848f5; 
  color: white;
}

.filter-button:hover {
  background-color: #3838d8;
}

.btn-page {
  margin: 10px;
  background-color: #4848f5;
  color: white;
}

.btn-page:hover {
  background-color: #3838d8;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
}

/* Loading spinner */
.spinner {
  border: 6px solid rgba(0, 0, 0, 0.1); /* Increase the border size */
  border-left-color: #4848f5;
  border-radius: 50%;
  width: 80px; /* Increase the size */
  height: 80px; /* Increase the size */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.no-authors {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.radio-container .selected-icon {
  color: #4848f5; /* Icon color for selected */
  margin-left: 0.5rem; /* Adjust spacing if needed */
}

.radio-group {
  display: flex;
  align-items: center;
  gap: 8px;  
  justify-content: flex-start;  
}

.radio-group label {
  display: flex;  
  align-items: center;
  font-size: 15px; 
  line-height: 1.5;
  gap: 5px;
}

.radio-group input {
  height: 20px; 
  vertical-align: middle; 
}

.downloadbutton-container {
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 0px; 
  justify-content: flex-end;  
}

.downloadbutton-container button {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 15px;
  line-height: 1.5;
  vertical-align: middle;
}

.download-button:hover {
  background-color: #3838d8;
}

.sort-dropdown {
  width: 150px;
  vertical-align: middle; 
}